import { Box, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import '../styles/style.css';
import { Button } from "@mui/base";


interface ISelectedNfts {
  title: string;
  quantity: number;
}
interface IPlanCard {
  image: string;
  plan: string;
  title: string;
  planType: 'nft' | 'supernode';
  selectedPlan: string;
  selectedNfts?: ISelectedNfts[]
  setSelectedNfts?: React.Dispatch<React.SetStateAction<ISelectedNfts[]>>
  setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
}

export default function PlanCard({
  image,
  title,
  selectedPlan,
  setSelectedPlan,
  plan,
  planType,
  selectedNfts,
  setSelectedNfts
}: IPlanCard) {

  const [darkBackgroundColor, setDarkBackgroundColor] = useState('1B1B1D');
  const [lightBackgroundColor, setLightBackgroundColor] = useState('FFFFFF');
  const [quantity, setQuantity] = useState<number>(0)

  function incrementQuantity() {
    setQuantity((prevQuantity) => prevQuantity + 1);
  }

  function decrementQuantity() {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 0));
  }

  function updateNftsToBuy() {
    if (selectedNfts && setSelectedNfts) {
      const newItem = {
        title: plan, quantity
      };

      let updatedNftsToBuy = selectedNfts.filter((i) => i.title !== plan);

      if (quantity > 0) {
        updatedNftsToBuy = [...updatedNftsToBuy, newItem];
      }

      setSelectedNfts(updatedNftsToBuy);
    }
  }

  useEffect(() => {
    updateNftsToBuy();
  }, [quantity]);

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const xPercent = (x / rect.width) * 100;
      const yPercent = (y / rect.height) * 100;

      e.target.style.setProperty("--x", xPercent + "%");
      e.target.style.setProperty("--y", yPercent + "%");
    };

    const cards = document.querySelectorAll(".card");
    cards.forEach((card) => {
      card.addEventListener("mousemove", handleMouseMove);

      return () => {
        card.removeEventListener("mousemove", handleMouseMove);
      };
    });
  }, []);

  useEffect(() => {
    if (selectedPlan === plan && planType !== 'nft') {
      setDarkBackgroundColor('#303032')
      setLightBackgroundColor('#F6F8FF')
    }
    else {
      setDarkBackgroundColor('#1B1B1D')
      setLightBackgroundColor('#FFFFFF')
    }
  }, [selectedPlan])

  return (
    <Box display={planType === 'nft' ? 'flex' : 'unset'} flexDirection={planType === 'nft' ? 'column' : 'unset'} width={'100%'}>
      <motion.div whileTap={{ scale: 1.0125 }} style={{ width: "100%", padding: '1px', }} className="cardAbove">
        <Box
          className={planType === 'supernode' ? 'card' : ''}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => setSelectedPlan(plan)}
          borderRadius={"16px"}
          p={"28px"}
          width={'100%'}
          sx={{
            zIndex: '2',
            width: '100%',
            border: (selectedPlan === plan && planType !== 'nft') ? '0.938px solid #8D4BE1' : '',
            cursor: 'pointer',
            background: localStorage.getItem('mui-mode') === 'light' ? lightBackgroundColor : darkBackgroundColor,
          }}
        >
          <Stack
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap='8px'
          >
            <img src={image} style={{ width: planType === 'nft' ? '400px' : '64px', marginBottom: '4px', borderRadius: '4px', maxWidth: '100%' }} />
            {planType === 'nft' ? (
              <Stack display={'flex'} flexDirection={'row'} gap={1} flexWrap={'wrap'}>
                <Typography color={(selectedPlan === plan && planType !== 'nft') ? localStorage.getItem('mui-mode') === 'light' ? '#0d0d0d' : '#fff' : '#b1b1b2'} fontSize={[24,36]}>CoNETian</Typography>
                <Typography color={(selectedPlan === plan && planType !== 'nft') ? localStorage.getItem('mui-mode') === 'light' ? '#0d0d0d' : '#fff' : '#b1b1b2'} fontSize={[24,36]} fontWeight={700}>NFT</Typography>
                <Typography color={(selectedPlan === plan && planType !== 'nft') ? localStorage.getItem('mui-mode') === 'light' ? '#0d0d0d' : '#fff' : '#b1b1b2'} fontSize={[24,36]} fontWeight={700}>- $100</Typography>
              </Stack>
            )
              :
              <Typography textAlign={"center"} color={(selectedPlan === plan && planType !== 'nft') ? localStorage.getItem('mui-mode') === 'light' ? '#0d0d0d' : '#fff' : '#b1b1b2'} fontSize={13}>{title}</Typography>
            }


          </Stack>
        </Box>
      </motion.div >
      {/*             {planType === 'nft' && (
        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} marginTop={4} marginBottom={4} gap={4}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} borderRadius={'12px'} style={{ backgroundColor: '#262626', width: '42px', height: '32px', cursor: 'pointer' }} onClick={decrementQuantity}><Typography color={'#fff'} textAlign={'center'} alignSelf={'center'}>-</Typography></Box>
          <Typography fontSize={'28px'} lineHeight={'36px'}>{quantity}</Typography>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} borderRadius={'12px'} style={{ backgroundColor: '#262626', width: '42px', height: '32px', cursor: 'pointer' }} onClick={incrementQuantity}><Typography color={'#fff'} textAlign={'center'} alignSelf={'center'} >+</Typography></Box>
        </Stack> 
      )}*/}
    </Box>
  );
}
