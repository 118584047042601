export const dAppsEn = {
    'titleWhite': 'CoNET',
    'titleBlue': 'Apps',
    'silentPassTitle': 'Silent Pass',
    'silentPassSubTitle': 'Silent Pass is an application based on the decentralized, zero-trust, privacy-first CONET Layer Minus network.',
    'discover': 'Discover',
    'bringTitle': 'Bring your dApp to CoNET',
    'bringSubTitle': 'You can create dApps on CoNET without the need to learn a new toolkit. Your code, tools, and infrastructure are ready to use immediately.',
    'popular': 'Popular Now',
    'discoverButton': {
        'all': 'All',
        'newest': 'Newest',
        'design': 'Design',
        'gaming': 'Gaming',
        'communication': 'Communication',
        'entertainment': 'Entertainment',
        'media': 'Media',
        'office': 'Office',
        'tool': 'Tool'
    },
    'dApps': {
        'conetianTelegram': 'CoNETian\n\n(Telegram version)',
        'conetianWeb': 'CoNETian\n\n(Web version)',
        'proxyExtension': 'Conet Proxy Extension',
        'miningExtension': 'Conet Wallet-Mining Extension',
        'fx168': 'FX168',
        'silentProxy': 'Silent Pass Proxy',
        'silentVpn': 'Silent Pass VPN',
        'conetianMessenger': 'CoNETian Messenger',
        'conetianStorage': 'CoNETian Storage',
        'comingSoon': 'Coming Soon'
    }
}

export const dAppsZh = {
    'titleWhite': 'CoNET',
    'titleBlue': '应用程序',
    'silentPassTitle': 'Silent Pass',
    'silentPassSubTitle': 'Silent Pass 是一款基于去中心化、零信任、隐私优先的 CONET Minus 层网络的应用程序',
    'discover': '发现',
    'bringTitle': '将您的 dApp 带到 CoNET',
    'bringSubTitle': '您无需学习新的工具包，即可在 CoNET 上创建 dApps。您的代码、工具和基础设施可立即投入使用。',
    'popular': '现在流行',
    'discoverButton': {
        'all': '全部',
        'newest': '最新',
        'design': '设计',
        'gaming': '游戏',
        'communication': '交流',
        'entertainment': '娱乐',
        'media': '媒体',
        'office': '办公室',
        'tool': '工具'
    },
    'dApps': {
        'conetianTelegram': 'CoNETian\n\n(Telegram 版本)',
        'conetianWeb': 'CoNETian\n\n(网络版)',
        'proxyExtension': 'Conet 代理扩展',
        'miningExtension': 'Conet 沃莱特采矿延伸',
        'fx168': 'FX168',
        'silentProxy': 'Silent Pass 代理',
        'silentVpn': 'Silent Pass 虚拟专用网',
        'conetianMessenger': 'CoNETian 信使',
        'conetianStorage': 'CoNETian 存储',
        'comingSoon': '即将推出'
    }
}