export const dashboardEn = {
    'titleWhite': 'Welcome to',
    'titleBlue': ' CoNET',
    'smart': 'CNTP Smart Contract',
    'allWallets': 'All wallets',
    'numberOfWallets': 'Number of wallets',
    'currentWallet': 'Current wallet',
    'currentBalance': 'Current balance',
    'cloudNodes': 'Cloud nodes',
    'accountBalance': 'Account Balance',
    'your': 'Your Wallets',
    'balance': 'Balance',
    'YesAssets': 'You have assets to claim!',
    'miningToast': 'Mining Started',
    'NoAssets': 'You have no assets to claim',
    'claim': 'CLAIM NOW',
    'nodesTable': {
        'address': 'Address',
        'status': 'Status',
        'ip': 'IP Address',
        'region': 'Region'
    },
    'learn': 'Learn More',
    'miningStarted':"Mining Started",
    'democratizing': 'Democratizing the CoNET network',
    'become': 'Become a Guardian to boost your earnings',
    'miningComponent': {
        'reconecting': 'You’re reconnecting',
        'lost': 'Connection Lost',
        'lostText': 'You have lost connection with the server. Try again later.',
        'dismiss': 'Dismiss',
        'onLine': 'Online Miners',
        'reward': 'Reward Rate',
        'stop': 'Stop DePIN',
        'reconect': 'Reconnect Automatically',
        'start': 'Click to start mining',
        'starting': 'Node starting ...'
    },
    referrals: {
        'title': 'Referrals',
        'white1': 'Share your link and ',
        'blue': 'expand',
        'white2': 'your impact',
        'subTitle': 'Spread the wordwith our unique referral program.Share your link with friends, family, or coworkers.',
        'dot1': 'Earn more points',
        'dot2': 'Get a 10% commission if your Invitee become a Guardian'
    },
    'perSecond': 'CNTP/PER second',
    'free': 'Free mining',
    'online': 'Online',
    'guardian': 'Guardian mining',
    'invited': 'Invited Guardians',
    'copyReferral': 'Copy your Referral Link',
    'copyLink': 'Copy link',
    'see': 'See your referees',
    'inviter': 'Your inviter',
    'type': 'Type',
    'status': 'Status',
    'comission': 'Your Commission',
    'yourReferrals': 'Your Referrals',
    'superNodePanel': {
        'title1': 'Embracing',
        'titleBlue': 'Decentralization',
        'title2': 'for Everyone',
        'pay': 'Pay in USDT or ETH or BNB or AETH',
        'bring': 'Bring your assets from other networks',
        'stargate': 'Stargate Bridge',
        'yourBalance': 'Your Available Balance',
        'arbitrum': 'Arbitrum Network',
        'eth': 'Ethereum Network',
        'bsc': 'Binance Network',
        'getBalance': 'Get Assets Balance',
        'refresh': 'Refresh Balance',
    },
    buyCard: {
        'nftPurchase': 'Purchase NFT',
        'superNodePurchase': 'Become a Super Node',
        'join': 'Join our Presale',
        'choose': 'Choose a plan',
        'guardianPlan': 'Guardian Plan',
        'current': 'Current Account',
        'quantity': 'Select Quantity',
        'currentOwned': 'Current Owned',
        'booster': 'Booster coefficient',
        'guardian': 'Guardian',
        'only': 'Only',
        'available': 'available',
        'total': 'Total',
        'selectToken': 'Select your token',
        'agreement': 'Agreement',
        'purchase': 'Purchase',
        'secure': 'Secure Payment',
        'agent': 'Agent Wallet',
        'insufficient': 'Insufficient Funds',
        'confirm': 'Confirm your order',
        'unable': 'Unable to get the GasFee',
        'paying': 'Paying with',
        'wallet': 'Wallet',
        'summary': 'Summary',
        'gas': 'Gas Fee',
        'confirmPayment': 'Confirm Payment',
        'quote': 'Quote updates in',
        'plan': 'Plan',
        'transaction': 'Transaction in progress',
        'wait1': 'Your order completion time may vary, please wait',
        'wait2': 'and we’ll let you know when it’s completed.',
        'startingPurchase': 'Starting Purchase...',
        'transfering': 'Transfering asset to CONET...',
        'waitConfirmation': 'Waiting transfering confirmation...',
        'finalizing': 'Finalizing purchase...',
        'waitPurchase1': 'The purchase process can take up to 24 hours, wait until',
        'waitPurchase2': 'it’s finished, when it’s finished you’ll be notified.',
        'processing': 'Processing',
        'declained': 'Purchase declined by CoNET',
        'sendMessage': 'Please, send us a message',
        'succesTitleWhite': 'The transaction',
        'successTitleBlue': 'was successful',
        'congrats': 'Congratulations!',
        'congratsText1': 'You now have',
        'congratsText2': 'nodes',
        'make': 'Make new one',
        'goTo': 'Go to profile',
    },
    'icons': {
        'receive1': 'Receive CoNET Power Card',
        'free': 'Free use of DCPN network',
        'share1': 'Share CoNET Operator Revenue',
        'share2': 'Share CoNET Hardware Miner Revenue',
        'receive2': 'Receive Special CoNET Airdrop',
        'own': 'Own Super Cloud Nodes'
    },
    'nodeFooter': {
        'soon': 'COMING SOON',
        'title': 'Get rewards by sharing your idle resources',
        'band': 'BANDWIDTH',
        'bandShare': 'Share your unused bandwidth resources',
        'saas': 'SAAS',
        'saasShare': 'Share your idle CPU capacity.',
        'storage': 'Storage',
        'storageShare': 'Share your unused disk space.',
        'boost': 'Boost',
        'boostStalking': 'Staking ? $CONET with 90 days locked'
    }

}

export const dashboardZh = {
    'titleWhite': '欢迎来到',
    'titleBlue': ' CoNET',
    'smart': 'CNTP 智能合约',
    'allWallets': '所有钱包',
    'numberOfWallets': '钱包数量',
    'currentWallet': '当前钱包',
    'currentBalance': '当前余额',
    'cloudNodes': '云节点',
    'accountBalance': '账户余额',
    'your': '我的钱包',
    'balance': '余额',
    'YesAssets': '您有资产可以申请！',
    'miningToast': '开始采矿',
    'NoAssets': '无资产需领取',
    'claim': '立即申请',
    'nodesTable': {
        'address': '钱包地址',
        'status': '现状',
        'ip': 'IP 地址',
        'region': '地区'
    },
    'learn': '了解更多',
    'miningStarted': '开采已开始',
    'democratizing': 'CoNET网络实现民主化',
    'become': '成为守护者加速收益',
    'miningComponent': {
        'reconecting': '您正在重新连接',
        'lost': '连接丢失',
        'lostText': '您与服务器失去了连接。稍后再试。',
        'dismiss': '解散',
        'onLine': '在线矿工',
        'reward': '奖励率',
        'stop': '停止 DePIN',
        'reconect': '自动重连',
        'start': '点击开始挖矿',
        'starting': '节点启动 ...'
    },
    referrals: {
        'title': '转介',
        'white1': '分享您的链接并 ',
        'blue': '展开',
        'white2': '您的影响',
        'subTitle': '通过推荐方式将链接分享给朋友, 家人, 同事',
        'dot1': '获得更多积分',
        'dot2': '被邀请人成为守护者可获得10%佣金'
    },
    'perSecond': 'CNTP/秒',
    'free': '免费挖矿',
    'online': '在线',
    'guardian': '守护者挖矿',
    'invited': '受邀守护者',
    'copyReferral': '复制您的推荐链接 ',
    'copyLink': '复制链接 ',
    'see': '查看您的被邀请人',
    'inviter': '您的邀请人',
    'type': '类型',
    'status': '现状',
    'comission': '您的委员会',
    'yourReferrals': '您的推荐',
    'superNodePanel': {
        'title1': '拥抱',
        'titleBlue': '非集中化',
        'title2': '为每个人',
        'pay': '使用USDT, ETH, BNB 或AETH 支付',
        'bring': '从其他网络导入资产',
        'stargate': 'Stargate 桥',
        'yourBalance': '您的可用余额',
        'arbitrum': 'Arbitrum网',
        'eth': '以太坊',
        'bsc': '币安',
        'getBalance': '获得资产余额',
        'refresh': '刷新平衡',
    },

    buyCard: {
        'nftPurchase': '购买NFT',
        'superNodePurchase': '成为守护者',
        'join': '参与预售',
        'choose': '选择计划',
        'guardianPlan': '守护者',
        'current': '当前账户',
        'quantity': '选择数量',
        'currentOwned': '已购守护者',
        'booster': '加速速率',
        'guardian': '守护者',
        'only': '只有',
        'available': '可用',
        'total': '总计',
        'selectToken': '选择币种',
        'agreement': '合约',
        'purchase': '合约',
        'secure': '安全支付',
        'agent': '代理钱包',
        'insufficient': '余额不足',
        'confirm': '确认订单',
        'paying': '付款方式',
        'wallet': '钱包',
        'summary': '摘要',
        'gas': '燃气费',
        'confirmPayment': '确认付款',
        'unable': '无法获取 GasFee',
        'quote': '报价更新',
        'plan': '计划',
        'transaction': '交易进行中',
        'wait1': '订单完成时间可能会有所不同，请稍候',
        'wait2': '完成后我们会通知您。',
        'startingPurchase': '开始购买...',
        'transfering': '向 CONET 转移资产...',
        'waitConfirmation': '等待转账确认...',
        'finalizing': '最后确定购买...',
        'waitPurchase1': '购买过程可能需要 24 小时，请等待',
        'waitPurchase2': '完成后，我们会通知您。',
        'processing': '加工',
        'declained': 'CoNET 拒绝购买',
        'sendMessage': '请给我们留言',
        'succesTitleWhite': '交易',
        'successTitleBlue': '很成功',
        'congrats': '祝贺你',
        'congratsText1': '您现在拥有',
        'congratsText2': '结点',
        'make': '新建一个',
        'goTo': '转到简介',
    },
    'icons': {
        'receive1': '获得CoNET 智能便携矿机',
        'free': '免费使用DCPN网络',
        'share1': '共享CoNET运营收入',
        'share2': '共享CoNET硬件矿机收入',
        'receive2': '获得CoNET空投',
        'own': '拥有超级云节点'
    },
    'nodeFooter': {
        'soon': '敬请期待',
        'title': '共享闲置资源获得奖励',
        'band': '带宽',
        'bandShare': '共享您闲置的带宽资源',
        'saas': 'SAAS',
        'saasShare': '共享您闲置的CPU能力',
        'storage': '存储',
        'storageShare': '共享您闲置的硬盘空间',
        'boost': '加速',
        'boostStalking': '质押? $CONET代币锁定90天'
    }
}