"use client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next/initReactI18next";


import { unlockWalletEn, unlockWalletZh } from "./translations/unlockWallet";
import { welcomeEn, welcomeZh } from "./translations/welcome";
import { createWalletEn, createWalletZh } from "./translations/createWallet";
import { dAppsEn, dAppsZh } from "./translations/dApps";
import { leaderboardEn, leaderboardZh } from "./translations/leaderboard";
import { copyEn, copyZh } from "./translations/copyYourSecret";
import { dashboardEn, dashboardZh } from "./translations/dashboard";
import { footerEn, footerZh } from "./translations/footer";
import { settingsEn, settingsZh } from "./translations/settings";
import { sideBarEn, sideBarZh } from "./translations/sideBar";


const resources = {
    'en': {
        translation: {
            unlockWallet: unlockWalletEn,
            welcome: welcomeEn,
            createWallet: createWalletEn,
            dApps: dAppsEn,
            leaderboard: leaderboardEn,
            copy: copyEn,
            dashboard: dashboardEn,
            footer: footerEn,
            settings: settingsEn,
            sideBar: sideBarEn,
        }
    },

    'zh': {
        translation: {
            unlockWallet: unlockWalletZh,
            welcome: welcomeZh,
            createWallet: createWalletZh,
            dApps: dAppsZh,
            leaderboard: leaderboardZh,
            copy: copyZh,
            dashboard: dashboardZh,
            footer: footerZh,
            settings: settingsZh,
            sideBar: sideBarZh
        }
    }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;