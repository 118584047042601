import { useState } from "react";
import { Typography, SvgIcon, Box, TextField, CircularProgress } from "@mui/material";
import { motion, Variants } from "framer-motion";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useRefreshStore } from "../../../../../API/useRefreshStore";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isArray } from "chart.js/dist/helpers/helpers.core";
import { ReactComponent as defaultProfilePicture } from '../assets/default_profile.svg'
import { useTranslation } from "react-i18next";

export default function DropdownFeaturedWallets({
  currentWalletKeyId,
  receiver,
  setReceiver,
  handleReceiverAddressChange,
  featuredWallets,
  isCheckingReceiverAddress,
  isReceiverAddressValid
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation()
  const walletStore = useRefreshStore((state) => state.wallets);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className='menu'
    >
      <motion.div
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: "100%",
          padding: "8px 0px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: '1px solid rgb(213, 213, 213)',
          alignItems: "center",
        }}
      >
        <Typography
          color={
            localStorage.getItem("mui-mode") === "light" ? "#0D0D0D" : "#F6F1F2"
          }
          marginRight={"5px"}
        >
          {t('settings.selectWallet')}
        </Typography>
        <motion.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 },
          }}
          transition={{ duration: 0.2 }}
          style={{ originY: 0.55 }}
        >
          <SvgIcon
            component={KeyboardArrowDownIcon}
            inheritViewBox
            sx={{ fontSize: 16 }}
          />
        </motion.div>
      </motion.div>
      <Box
        maxHeight={"150px"}
        height={isOpen ? "fit-content" : "20px"}
        marginBottom={isOpen ? "20px" : "0"}
      >
        <motion.ul
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
                delayChildren: 0.3,
                staggerChildren: 0.05,
              },
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
              },
            },
          }}
          style={{
            pointerEvents: isOpen ? "auto" : "none",
            overflow: "auto",
            height: "150px",
            scrollbarWidth: "thin",
            marginTop: "8px",
            borderBottom:"2px solid #d5d5d5", 
            padding:'16px'
          }}
        >
          <motion.li style={{ marginBottom: "5px", marginTop: "5px", fontWeight: '600', fontSize:'14px', listStyleType: "none" }}>
          {t('settings.yourWallets')}
          </motion.li>
          {walletStore && walletStore?.length > 0 &&
            walletStore?.map((wallet: any) => {
              if (wallet?.keyID !== currentWalletKeyId) {
                return (
                  <motion.li
                    onClick={() => {
                      console.log("aqui:", wallet)
                      handleReceiverAddressChange(wallet.keyID);
                      setIsOpen(false);
                    }}
                    style={{ cursor: "pointer", margin: "12px 0px", display: 'flex', gap: "12px", alignItems: 'center' }}
                  >
                    <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 24 }} />
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography
                        fontSize={"12px"}
                        fontWeight={600}
                        color={"grey"}
                      >
                        {wallet && wallet.data && wallet.data.nickname ? wallet.data.nickname : "Anonymous Wallet"}
                      </Typography>

                      <Typography
                        fontSize={"14px"}
                        fontWeight={200}
                        color={"grey"}
                      >
                        {wallet.keyID}
                      </Typography>
                    </Box>
                  </motion.li>
                );
              }
            })}
          {/* <motion.li style={{ marginBottom: '5px', marginTop: '5px' }}>Other Wallets</motion.li>
          {featuredWallets.map((w: any) =>
            <motion.li onClick={() => setReceiver(w.toString())} style={{ cursor: 'pointer', marginBottom: '2px' }}><Typography fontSize={'14px'} fontWeight={200} color={'grey'}>{w}</Typography></motion.li>
          )} */}
        </motion.ul>
      </Box>

      <Box display="flex" flexDirection="row" gap="8px">
        <TextField
          error={!isReceiverAddressValid && !isCheckingReceiverAddress && receiver !== ""}
          helperText={!isReceiverAddressValid && !isCheckingReceiverAddress && receiver !== "" ? "Invalid Address" : ""}
          variant='standard'
          style={{ width: "100%" }}
          placeholder={t('settings.selectPaste')}
          value={receiver}
          onChange={(e) => handleReceiverAddressChange(e.target.value)}
        />
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
          display={isCheckingReceiverAddress ? "flex" : "none"}
        >
          <CircularProgress size={24} color='inherit' /> Checking
        </Box>
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
          display={isReceiverAddressValid && !isCheckingReceiverAddress && receiver !== "" ? "flex" : "none"}
        >
          <CheckCircleIcon color='success' fontSize='small' />
        </Box>
      </Box>
    </motion.nav>
  );
}
