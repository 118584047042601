import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface IAppCardsProps {
    appImageWhite: string;
    appImageBlack: string;
    title: string;
    appUrl?: string;
    renderTags: string[];
    tags: string[];
    soon: boolean;
}


export default function AppCard({ appImageWhite, appImageBlack, title, appUrl, renderTags, tags, soon }: IAppCardsProps) {
    const { t } = useTranslation()
    return (

        <Stack style={{ height: '400px', width: '300px', cursor: !soon ? 'pointer' : 'default' }} onClick={() => appUrl ? window.open(appUrl, '_blank'): null}>
            <img src={localStorage.getItem('mui-mode') === 'light' ? appImageWhite : appImageBlack} alt="logoImge" height={'60%'} width={'100%'} style={{ overflow: 'hidden', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} />
            <div style={{ marginTop: '-41px', backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#fff" : "#262527", border: `1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"}`, borderBottomLeftRadius: '8px', borderBottom:  `1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"}`, borderBottomRightRadius: '8px', height: '160px', padding: '24px' }}>
                <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#fff"} fontWeight={700}>{title}</Typography>
                <div style={{display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '8px'}}>
                {renderTags.map((t, i) => {
                    return <Typography color={'#858586'} fontWeight={700} key={i}>{t.charAt(0).toUpperCase() + t.slice(1)}</Typography>
                })}
                </div>
                {soon && (
                    <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#fff"} fontWeight={700} mt={4}>{t('dApps.dApps.comingSoon')}</Typography>
                )}
            </div>
        </Stack>


    )
}