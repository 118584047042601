export const sideBarEn = {
    'dashboard': 'Dashboard',
    'leaderboard': 'Leaderboard',
    'apps': 'Apps',
    'language': 'Language',
}

export const sideBarZh = {
    'dashboard': '仪表板',
    'leaderboard': '排行榜',
    'apps': '应用商店',
    'language': '语言',
}